<template>
	<div id="purchase">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>办公</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">公务申购</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content u-f">
				<div class="content-left">
					<div class="u-f-item meun-item" :class="meunLeftIndex == 1 ? 'meun-active' : ''" @click="meunLeftTap(1)">
						<div class="iconfont icon-fabu"></div>
						<div>我的申请</div>
					</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 2 ? 'meun-active' : ''" @click="meunLeftTap(2)">
						<div class="iconfont icon-caogao"></div>
						<div>我的抄送</div>
					</div>
					<div class="font_blod font_16 p-t-b">审核</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 3 ? 'meun-active' : ''" @click="meunLeftTap(3)">
						<div class="iconfont icon-yishenhe"></div>
						<div>待审核</div>
					</div>
					<div class="u-f-item meun-item" :class="meunLeftIndex == 4 ? 'meun-active' : ''" @click="meunLeftTap(4)">
						<div class="iconfont icon-yishenhe1"></div>
						<div>已审核</div>
					</div>
					
				</div>
				<!-- 流程申请 -->
				<div class="content-right">
					<div class="title u-f-item u-f-jsb">
						<div>
							共{{ total }}条数据
							<!-- <span class="btn" @click="selectAll">选择全部</span> -->
						</div>
					</div>
					<el-table
						:data="list"
						height="98%"
						ref="multipleTable"
						tooltip-effect="dark"
						style="width: 100%;border: 1px solid #EEEEEE;"
						key="2"
					>
						<el-table-column label="序号" type="index" width="80" align="center"></el-table-column>
						<el-table-column prop="title" label="标题" align="center"></el-table-column>
						<!-- <el-table-column prop="inventory" label="物品清单" align="center"></el-table-column> -->
						<el-table-column prop="petitioner_name" label="申请人" v-if="meunLeftIndex!=1" align="center"></el-table-column>
						<el-table-column prop="explain" label="用途" align="center"></el-table-column>
						<el-table-column prop="expenditure" label="预算金额" align="center"></el-table-column>
						<!-- <el-table-column prop="type_text" label="请假类型" align="center"></el-table-column> -->
						<el-table-column prop="createtime" label="申请时间" align="center">
							<template slot-scope="scope">
								<span>{{scope.row.createtime|getLocalTime}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="status" label="审核状态" align="center">
							<template slot-scope="scope">
								<el-tag v-if="scope.row.status == 1">待审核</el-tag>
								<el-tag v-if="scope.row.status == 2">已通过</el-tag>
								<el-tag v-if="scope.row.status == 3">未通过</el-tag>
								<el-tag v-if="scope.row.status == 4">已撤回</el-tag>
							</template>
						</el-table-column>
						<el-table-column align="center" label="操作" width="150">
							<template slot-scope="scope">
								<el-button @click="openDetails(scope.row)" type="text" size="small">详情</el-button>
								<el-button @click="print(scope.row)" type="text" size="small">打印</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="u-f-right" style="padding: 15px 0;">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:page-size="limit"
							layout="total, prev, pager, next"
							:total="total"
						></el-pagination>
					</div>
				</div>
			</div>
			
			<!-- 详情 -->
			<el-dialog title="公物申购" :visible.sync="activityVisible" width="650px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f input-box">
						<div class="input-lebal">标题：</div>
						<div>{{ info.title }}</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">物品清单：</div>
						<div v-for="(item,index) in info.inventory" :key="index">
							{{item.name}}*{{item.number}} <span v-if="info.inventory.length!=index+1">,</span>
						</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">用途：</div>
						<div>{{ info.explain }}</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">预算金额：</div>
						<div>{{ info.expenditure }}</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">申请人：</div>
						<div>{{ info.petitioner_name }}</div>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal">审核状态：</div>
						<div>{{ info.status_text }}</div>
					</div>
					<div class="u-f-item input-box" v-if="info.audit_opinions">
						<div class="input-lebal">审核备注：</div>
						<div>{{ info.audit_opinions }}</div>
					</div>
				</div>
				<div style="min-height: 200px;padding-left: 60px;margin-top: 40px;">
					<el-steps direction="vertical">
						<el-step title="审核人" v-if="info.audit&&info.audit.length">
							<div slot="description" style="padding-bottom: 10px;">
								<div v-for="(item,index) in info.audit" :key="index" style="margin-top: 10px;">
									<div class="u-f-item u-f-jsb">
										<div>{{item.audit_username}}</div>
										<div v-if="info.status!=4">
											<span v-if="item.handle==0">待上级审批</span>
											<span v-if="item.handle==1">待审批</span>
											<span v-if="item.handle==2">已审批</span>
											<span v-if="item.audit_time">{{item.audit_time|getLocalTime}}</span>
										</div>
										<div v-if="info.status==4">
											<span>已撤回</span>
										</div>
									</div>
									<div>{{item.audit_opinions}}</div>
								</div>
							</div>
						</el-step>
						<el-step title="承办人" v-if="info.exec&&info.exec.length">
							<div slot="description" style="padding-bottom: 10px;">
								<div v-for="(item,index) in info.exec" :key="index" style="margin-top: 10px;">
									<div class="u-f-item u-f-jsb">
										<div>{{item.audit_username}}</div>
										<div v-if="info.status!=4">
											<span v-if="item.handle==0">待上级审批</span>
											<span v-if="item.handle==1">待审批</span>
											<span v-if="item.handle==2">已审批</span>
											<span v-if="item.audit_time">{{item.audit_time|getLocalTime}}</span>
										</div>
										<div v-if="info.status==4">
											<span>已撤回</span>
										</div>
									</div>
									<div>{{item.audit_opinions}}</div>
								</div>
							</div>
						</el-step>
						<el-step title="抄送人" v-if="info.ectype&&info.ectype.length">
							<div slot="description">
								<div v-for="(item,index) in info.ectype" :key="index" style="margin-top: 10px;">
									<div class="u-f-item u-f-jsb">
										<div>{{item.audit_username}}</div>
										<div>
											<span v-if="info.exec_status==2">已抄送</span>
											<span v-if="info.status==4">已撤回</span>
											<span v-if="info.updatetime&&info.exec_status==2">{{info.updatetime|getLocalTime}}</span>
										</div>
									</div>
									<div>{{item.audit_opinions}}</div>
								</div>
							</div>
						</el-step>
					</el-steps>
				</div>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			meunLeftIndex: 1,
			total: 0,
			list:[],
			page:1,
			limit:10,
			activityVisible:false,
			info:''
		};
	},
	filters:{
		getLocalTime(nS) {  
			return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/,' ');  
		}
	},
	mounted() {
		this.getMyDocumentList();
	},
	methods: {
		print(e) {
		    let {href}= this.$router.resolve({
				path: "/print",
				query: {
				    id: e.id,
					type:3
				}
			});
			window.open(href, '_blank');
		},
		// 分页
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			if (this.meunLeftIndex == 1) {
				this.getMyDocumentList(1)
			} else if (this.meunLeftIndex == 2) {
				this.getMyProcess(2)
			} else if (this.meunLeftIndex == 3) {
				this.getMyProcess(3)
			} else if (this.meunLeftIndex == 4) {
				this.getMyProcess(4)
			}
		},
		meunLeftTap(index) {
			this.meunLeftIndex = index;
			this.page = 1;
			if (index == 1) {
				this.getMyDocumentList(1)
			} else if (index == 2) {
				this.getMyProcess(2)
			} else if (index == 3) {
				this.getMyProcess(3)
			} else if (index == 4) {
				this.getMyProcess(4)
			}
		},
		// 获取我的申请
		getMyDocumentList(type){
			let data = {
				page:this.page,
				limit:this.limit
			}
			this.$api.rule.getMaterialsList(data).then(res=>{
				if(res.data.code==1){
					this.list = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		},
		getMyProcess(type){
			let data = {
				page:this.page,
				limit:this.limit
			}
			if(type==2){data.type=2}
			if(type==3){
				data.type=1;
				data.filter = JSON.stringify({'status':1})
			}
			if(type==4){
				data.type=1;
				data.filter = JSON.stringify({'status':2})
			}
			this.$api.rule.getmaterialsProcess(data).then(res=>{
				if(res.data.code==1){
					this.list = res.data.data.rows;
					this.total = res.data.data.total;
				}
			})
		},
		// 详情
		openDetails(e) {
			this.getmaterialsGet(e.id)
		},
		handleClose(done) {
			done();
		},
		// 资产管理
		getmaterialsGet(id) {
			this.$api.rule.getmaterialsGet({ id: id }).then(res => {
				if (res.data.code == 1) {
					this.info = res.data.data;
					this.activityVisible = true;
				}
			});
		},
	}
};
</script>

<style lang="scss">
.el_option {
	display: none !important;
}
#purchase {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.nav-tab-bar-box {
		background-color: #ffffff;
		padding: 0.52vw;
		margin-top: 1.04vw;
		.nav-tab-bar {
			.nav-tab-item {
				color: #6e7a87;
				padding: 0 0.78125vw;
				border-right: 1px solid #f3f3f3;
				cursor: pointer;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
			&div:last-child {
				border-right: none;
			}
			.active {
				font-size: 0.833vw;
				color: #63acff;
				position: relative;
				font-weight: bold;
			}
			.active::after {
				position: absolute;
				content: '';
				height: 2px;
				left: 0.78125vw;
				right: 0.78125vw;
				background-color: #63acff;
				bottom: -0.53125vw;
			}
		}
	}
	.content {
		background-color: #ffffff;
		height: 85vh;
		margin-top: 20px;
		.content-left {
			width: 20%;
			border-right: 1px dashed #eeeeee;
			height: 79vh;
			padding: 30px 20px 30px 30px;
			.meun-item {
				cursor: pointer;
				padding: 10px 20px;
				color: #96a2aa;
				.iconfont {
					margin-right: 10px;
				}
			}
			.meun-active {
				background-color: #ddeeff;
				color: #3f9ffe;
			}
		}
		.content-right {
			width: 80%;
			height: 74vh;
			padding: 15px 20px;
			.title {
				padding: 0 0 15px 0;
				.btn {
					background-color: #f5f5f5;
					padding: 5px;
				}
				.btn:hover {
					color: #4998ff;
					background: #e4f1ff;
					cursor: pointer;
				}
			}
			.el-col {
				margin-bottom: 20px;
			}
			.content-item {
				border: 2px solid #f2f3f4;
				.box1 {
					padding: 35px 20px;
					i {
						color: #1890ff;
						margin-right: 5px;
						font-size: 20px;
					}
					span {
						font-weight: bold;
					}
				}
				.btn {
					background-color: #fafafa;
					padding: 10px 0;
					color: #b5bdc2;
					cursor: pointer;
				}
			}
		}
	}

	.el-table th > .cell,
	.el-table .cell {
		overflow: hidden; /*超出部分隐藏*/
		white-space: nowrap; /*不换行*/
		text-overflow: ellipsis; /*超出部分文字以...显示*/
	}
	.el-table thead {
		background: #fafafa !important;
	}
	.el-table th {
		padding: 15px 5px !important;
		background: #fafafa !important;
	}
	.el-table::before {
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		height: 600px;
		overflow-y: auto;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					width: 20%;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.input-lebal1 {
					width: 5vw;
					text-align: right;
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
				.upload-file {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					opacity: 0;
					cursor: pointer;
				}
				.file_box {
					padding: 5px;
					.el-icon-circle-check {
						display: block;
					}
					.el-icon-circle-close {
						display: none;
					}
				}
				.file_box:hover {
					color: #007aff;
					background-color: #f6f8fb;
					.el-icon-circle-check {
						display: none;
					}
					.el-icon-circle-close {
						display: block;
					}
				}
				.el-upload__tip {
					color: #ccc;
				}

				.table-head {
					height: 50px;
					min-height: 21.33333333px;
					background-color: #f6f8fb;
					border-bottom: 1px solid #f0f2f5;
					.class_name {
						font-weight: 700;
						height: 100%;
						border-right: 1px solid #f0f2f5;
						width: 4.85416667vw;
						min-width: 39.46666667px;
						color: #68b0fd;
						font-size: 0.729vw;
					}
					.week_name {
						height: 100%;
						border-right: 1px solid #f0f2f5;
						flex: 1;
						min-width: 64px;
						border-right: 1px solid #f0f2f5;
						color: #8b8b8d;
						font-size: 0.7291666666666666vw;
					}
				}
				.table-item {
					height: 50px;
					min-height: 26.66666667px;
					border-bottom: 1px solid #f0f2f5;
					.time_name {
						height: 100%;
						background-color: #f6f8fb;
						width: 4.85416667vw;
						min-width: 39.46666667px;
						border-right: 1px solid #f0f2f5;
						color: #8b8b8d;
						font-size: 0.7291666666666666vw;
					}
					.cell {
						position: relative;
						overflow-x: hidden;
						height: 100%;
						border-right: 1px solid #f0f2f5;
						width: 6.25vw;
						flex: 1;
						min-width: 64px;
						cursor: pointer;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-pack: center;
						-ms-flex-pack: center;
						justify-content: center;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						-webkit-box-orient: vertical;
						-webkit-box-direction: normal;
						-ms-flex-direction: column;
						flex-direction: column;
						transition: all 0.2s ease 0s;
						-moz-transition: all 0.2s ease 0s;
						-webkit-transition: all 0.2s ease 0s;
						-o-transition: all 0.2s ease 0s;
						-ms-transition: all 0.2s ease 0s;
						p {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							width: 4.94791667vw;
							min-width: 50.66666667px;
							margin: 0;
							padding: 0;
							border: 0;
							text-align: center;
							// font-size: 0.7291666666666666vw;
						}
						.cell_subject {
							color: #6f6f6f;
							margin-bottom: 0.26041667vw;
						}
						.cell_teacher {
							color: #a9b8c0;
						}
					}
					.active {
						background-color: #1890ff;
						.cell_teacher {
							color: #ffffff;
						}
						.cell_subject {
							color: #ffffff;
						}
					}
				}
				.teacher-list {
					display: flex;
					flex-wrap: wrap;
					width: 72%;
					.teacher-item {
						width: 100px;
						height: 30px;
						line-height: 30px;
						border-radius: 5px;
						text-align: center;
						margin-right: 10px;
						background-color: #eeeeee;
						margin-bottom: 10px;
						cursor: pointer;
					}
					.active {
						background-color: #007aff;
						color: #ffffff;
					}
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
	#audit {
		.el-dialog__body {
			padding: 0 20px 35px 20px;
			height: 300px;
		}
	}
}
.selectShow {
	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0;
		.user-list-box {
			.user-list-head {
				padding: 10px 20px;
				border-bottom: 1px solid #eeeeee;
				.user-type-list {
					.user-type-item {
						color: #8d9da6;
						cursor: pointer;
					}
					.active {
						color: #007aff;
						position: relative;
					}
					.active::after {
						position: absolute;
						width: 25px;
						height: 2px;
						background-color: #007aff;
						content: '';
						bottom: -5px;
						left: 0;
					}
					span {
						color: #cccccc;
						margin: 0 8px;
					}
				}
			}
			.user-list-content {
				height: 40vh;
				.left {
					height: 98%;
					overflow-y: auto;
					border-right: 1px solid #eeeeee;
					padding-top: 5px;
					.left-item {
						padding: 0 20px;
						height: 40px;
						line-height: 40px;
						color: #a4a4a4;
						cursor: pointer;
					}
					.active {
						background-color: #e4f1ff;
						color: #2d8cf0;
						position: relative;
					}
					.active:after {
						position: absolute;
						top: 0;
						bottom: 0;
						right: 0;
						width: 2px;
						content: '';
						background-color: #2d8cf0;
					}
				}
				.right {
					height: 37vh;
					padding: 10px 25px;
					overflow-y: auto;
					.list {
						display: flex;
						flex-wrap: wrap;
						.item {
							cursor: pointer;
							padding: 5px 15px;
							border-radius: 3px;
							background-color: #f3f3f3;
							margin-right: 10px;
							margin-bottom: 10px;
						}
						.active {
							background-color: #e4f1ff;
							color: #2d8cf0;
						}
					}
				}
			}
		}
	}
	.dialog-title {
		padding: 10px 0 5px 0;
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}
}
.el-step__description{
	padding-right: 0!important;
}
</style>
